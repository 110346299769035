<template>
  <div v-loading="loading" class="invite-wrap">
    <div class="invite-wrap-item">
      <div class="invite-wrap-item__title">초대 코드</div>
      <div class="invite-wrap-item__content">
        <div class="invite-wrap-item__code">{{ managementInfo.group_code }}</div>
        <el-button type="primary" size="large" @click="handleCopyInviteCode()">초대 코드 복사</el-button>
      </div>
    </div>
    <div class="invite-wrap-item">
      <div class="invite-wrap-item__title">초대 URL</div>
      <div class="invite-wrap-item__content">
        <div class="invite-wrap-item__url">https://www.jajakjajak.com/invite?code={{ managementInfo.group_code }}</div>
        <el-button type="primary" size="large" @click="handleCopyInviteLink()">URL 복사</el-button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { getMemberManagement } from "~/composables/php/group";

onMounted(() => {
  getInfo();
})

const managementInfo = ref({
  count_all: 0,
  count_join: 0,
  count_wait: 0,
  create_user_no: null,
  data_users: [],
  group_code: '',
  group_manager: '',
  group_no: '',
});

const loading = ref(true);

const route = useRoute();

function getInfo() {
  loading.value = true;
  getMemberManagement(route.params.roomId).then((res) => {
    managementInfo.value = res.data;
    loading.value = false;
  });
}

function handleCopyInviteCode() {
  navigator.clipboard.writeText(managementInfo.value.group_code).then(() => {
    ElMessage.success('초대 코드를 복사했습니다.\n원하는 곳에 공유해 주세요!');
  });
}

function handleCopyInviteLink() {
  navigator.clipboard.writeText(`https://jajakjajak.com/invite?code=${managementInfo.value.group_code}`).then(() => {
    ElMessage.success('초대 링크를 복사했습니다.\n원하는 곳에 공유해 주세요!');
  });
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/room/invite.scss';
</style>